
const isExternal = (link) => {

    //Check if `href` is the same origin.
    function sameOrigin(href) {
        if(!href) return false;
        var url = toURL(href);
        var loc = window.location;
        return loc.protocol === url.protocol &&
        loc.hostname === url.hostname &&
        loc.port === url.port;
    };

    /**
    * Convert to a URL object
    */
    function toURL(href){
        let hasDocument;
        if(typeof URL === 'function') {
            return new URL(href, location.toString());
        } else if(hasDocument) {
            var anc = document.createElement('a');
            anc.href = href;
            return anc;
        }
    }

    //make sure we're a link
    if (!link || !link.getAttribute) return false;

    // 1. "download" attribute
	// 2. rel="external" attribute
    if ( link.hasAttribute('download') || link.getAttribute('rel') === 'external' ) return false;

    // Check for mailto: in the href
    if ( link.hasAttribute('href') && link.getAttribute('href').indexOf('mailto:') > -1 ) return true;

    //if the link is a javascript placeholder its not external
    if( link.hasAttribute('href') && link.getAttribute('href') == 'javascript:;' ) return false;

    //if the link has a data-outbound attribute than we return false, because its an override
    if(link.dataset.outbound != undefined) return false;

    if( !sameOrigin(link.getAttribute('href')) ) return false

    //If we get this far its not external
    return false;
}

export default isExternal;